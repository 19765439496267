import { render, staticRenderFns } from "./MediaCardVideo.vue?vue&type=template&id=dca62022&scoped=true&lang=pug&"
import script from "./MediaCardVideo.vue?vue&type=script&lang=ts&"
export * from "./MediaCardVideo.vue?vue&type=script&lang=ts&"
import style0 from "./MediaCardVideo.vue?vue&type=style&index=0&id=dca62022&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dca62022",
  null
  
)

export default component.exports