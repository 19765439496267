





















































import {Component, Mixins, Watch} from 'vue-property-decorator';
import {vxm} from '@/store';
import {LoadModelDataInterface} from '@/types/userDataInterface';
import {MediaEnum} from '@/types/enums/MediaEnum';
import MomentDateMixin from '@/mixins/MomentDateMixin';
import DefaultAvatarMixin from '@/mixins/DefaultAvatarMixin';
import {VideoInterface} from '@/types/videoInterface';
import MediaCard from '@/components/MediaCard.vue';
import MediaCardVideo from '@/components/MediaCardVideo.vue';
import moment from 'moment';
import {PhotoInterface} from '@/types/photoInterface';
import Loader from '@/components/Loader.vue';

@Component({
  components: {MediaCard, MediaCardVideo, Loader},
})
export default class MediaDetail extends Mixins(MomentDateMixin, DefaultAvatarMixin) {
  contentIsReady = false;
  mediaItem: any = null;
  relatedVideos: VideoInterface[] = [];
  model: LoadModelDataInterface = {
    _id: '',
  };
  mediaTypes = MediaEnum;
  photos: PhotoInterface[] | string[] = [];
  selectedAlbumImgIndex = 0;
  isRequestVideos = false;
  params = {
    videoId: this.$route.params.id,
    page: 1,
    take: 6,
  };

  @Watch('$route.params.id') routeUpd() {
    this.getData();
    if (this.mediaItem.mediaType === this.mediaTypes.PICS_PLUS || this.mediaItem.mediaType === this.mediaTypes.PICS) {
      this.scrollToCurrentPhoto();
    }
  }

  get isSubscribed(): boolean {
    if (this.isMy) {
      return true;
    }
    if (this.model) {
      return !!vxm.user.subscriptions.items.find(
        (x) => x.performerId === this.model?._id && moment(x.expiredDate).isAfter(moment()),
      );
    }
    return false;
  }

  get isMy() {
    return vxm.user.data._id === this.model?._id;
  }

  mounted() {
    this.getData();
  }

  // loadMoreRelatedVideos() {
  //   if (!this.isRequestVideos) {
  //     this.relatedVideos;
  //   }
  // }

  getData() {
    let apiCall;
    if (this.$route.name === 'movie-details' && this.$route.params.id) {
      apiCall = vxm.model.getVideo;
      vxm.model
        .getRelatedVideos(this.$route.params.id)
        .then((res) => {
          this.relatedVideos = res.data;
          // this.relatedVideos.push(...res.data);
          // (this.params.page as number)++;
        })
        .catch((error) => {
          return error;
        });
    } else if (this.$route.name === 'clip-details' && this.$route.params.id) {
      apiCall = vxm.model.getAffiliateDetails;
    } else if (this.$route.name === 'photo-details' && this.$route.params.id) {
      apiCall = vxm.model.getPhoto;
    } else {
      this.$router.back();
    }
    apiCall(this.$route.params.id)
      .then((res) => {
        if (res.data) {
          this.mediaItem = res.data;
          if (!this.mediaItem.mediaType) {
            switch (this.$route.name) {
              case 'movie-details':
                this.mediaItem.mediaType = this.mediaItem.isForSale ? this.mediaTypes.VIDS_PLUS : this.mediaTypes.VIDS;
                break;
              case 'clip-details':
                this.mediaItem.mediaType = this.mediaTypes.CLIPS;
                break;
              case 'photo-details':
                this.mediaItem.mediaType = this.mediaItem.isForSale ? this.mediaTypes.PICS_PLUS : this.mediaTypes.PICS;
                break;
              default:
                this.mediaItem.mediaType = this.mediaTypes.VIDS;
            }
          }
          const userId = this.mediaItem.user
            ? typeof this.mediaItem.user === 'string'
              ? this.mediaItem.user
              : this.mediaItem.user._id
            : this.mediaItem.performer;
          vxm.fan
            .getPerformer(userId)
            .then((res) => {
              const isSameModel = this.model._id === res.data._id;
              this.model = res.data;
              if (
                (this.mediaItem.mediaType === this.mediaTypes.PICS_PLUS ||
                  this.mediaItem.mediaType === this.mediaTypes.PICS) &&
                !isSameModel
              ) {
                this.mediaItem.isForSale ? this.getAlbumGallery() : this.getPhotosGallery(1);
              }
            })
            .catch((error) => {
              return error;
            });
        }
      })
      .catch((error) => {
        return error;
      });
  }

  getAlbumGallery() {
    this.photos = this.mediaItem.thumbnails;
  }

  getPhotosGallery(page: number) {
    vxm.fan
      .getPhotosSearch({performerId: this.model._id, page: page, take: 20, isForSale: false})
      .then((res) => {
        if (page === 1) {
          this.photos = [];
        }
        this.photos.push(...res.data.items);
        if (this.photos.length < res.data.count) {
          this.getPhotosGallery(page + 1);
        } else {
          this.scrollToCurrentPhoto();
        }
      })
      .catch((error) => {
        return error;
      });
  }

  timeConvert(duration: number): string {
    let min = Math.floor(duration / 60) + '';
    let sec = duration - Math.floor(duration / 60) * 60 + '';
    min = min.length === 1 ? '0' + min : min;
    sec = sec.length === 1 ? '0' + sec : sec;
    return `${min}:${sec}`;
  }

  selectAlbumImg(index: number) {
    this.selectedAlbumImgIndex = index;
    this.goto(this);
  }

  goto(refName: any) {
    const box = document.querySelector('#wrapperSelector') as HTMLElement;
    const offsetTop = box.offsetTop;
    window.scrollTo(0, offsetTop);
  }

  selectImg(img: PhotoInterface) {
    this.mediaItem = img;
    this.selectedAlbumImgIndex = 0;
    this.$router.push(`/photo/${this.mediaItem._id}`);
  }

  changeImg(direction: number) {
    let index = this.mediaItem.isForSale
      ? this.selectedAlbumImgIndex
      : this.photos.findIndex((x) => x._id === this.$route.params.id);
    index =
      index + direction > this.photos.length - 1
        ? 0
        : index + direction < 0
        ? this.photos.length - 1
        : index + direction;
    this.mediaItem.isForSale ? this.selectAlbumImg(index) : this.selectImg(this.photos[index] as PhotoInterface);
    this.scrollToCurrentPhoto();
  }

  scrollToCurrentPhoto() {
    this.$nextTick(() => {
      const carouselContainer = this.$refs.carouselContainer as HTMLElement;
      const carouselItem = carouselContainer.querySelector('.selected') as HTMLElement;
      carouselContainer.scrollLeft = carouselItem.offsetLeft - 130;
    });
  }

  addErrorClass(event) {
    event.target.parentNode.classList.add('img-error');
  }

  navigate(id) {
    this.$router.push('/movie/' + id);
  }
}
